<template>
  <div>
    <a-card title="What is ePMS?" style="width: 100%">
      <p>
        The ePMS is an information system aimed to manage all phases of the Strategic
        Performance Management System: Performance Planning, Coaching and Mentoring,
        Performance Review and Feedback and Performance Rewarding and Development
        Planning. ePMS will provide features to allow creation and storage of performance
        management documents, the conduct of the assessment of a unit or staff performance
        vis-à-vis set targets, conduct coaching and mentoring, plan performance rewards and
        faculty development, and generate reports such as ranking of units, scorecards,
        coaching and monitoring journals, faculty development plan, amongst others.
      </p>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'VbDashboard',
  components: {},
}
</script>
